<script setup>
import { ref, computed } from 'vue'
import { useLayoutStore } from '~/stores/layout.store';
import { useMetaStore } from '~/stores/meta.store'

const metaStore = useMetaStore();
const layoutStore = useLayoutStore();

const meta = computed(() => metaStore.metaGetter());
const languages = computed(() => layoutStore.languagesGetter);

defineOgImage({
		url: meta.value.image,
});
</script>

<template>

	<Head>
		<base href="/" />
		<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
		<Title>{{ meta.title }}</Title>
		<Meta name="description" :content="meta.description" />
		<Meta name="twitter:title" :content="meta.title" />
		<Meta name="twitter:description" :content="meta.description" />
		<Meta name="twitter:card" content="summary" />
		<Meta property="og:title" :content="meta.title" />
		<Meta property="og:description" :content="meta.description" />
		<Meta property="og:url" :content="meta.url" />
		<Meta property="og:type" content="website" />
		<Meta property="og:site_name" content="Vartur Real estate" />
		<Meta property="fb:app_id" :content="meta.fb_app_id" />
		<Link rel="canonical" :href="meta.canonical_url" />
		<Link rel="alternate" hreflang="x-default" :href="meta.default_url" />
		<template v-for="(url, lang, index) in meta.href_langs" :key="index">
			<template v-if="languages.find(l => l.code == lang)">
				<Link  rel="alternate" :hreflang="lang" :href="url" />
			</template>
		</template>
	</Head>
</template>